import {
  MixpanelOfflineEventsKey,
  mixpanelOfflineEventsSchema,
} from "#src/hooks/mixpanel/config.ts";
import { useInterval } from "#src/hooks/useInterval.tsx";
import { InternetConnectionStatus } from "#src/hooks/useRootContext/hooks/useInternetConnectionStatus.tsx";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "#src/services/local-storage.client.ts";
import { type TrackingEventArgs } from "#src/services/mixpanel/events/index.ts";
import { trackEvent } from "#src/services/mixpanel/mixpanel.client.ts";
import { durationInMilliseconds } from "#src/utils/duration.ts";

const SEND_OFFLINE_EVENTS_INTERVAL = durationInMilliseconds({ minutes: 5 });

/**
 * This hook send Mixpanel events that occurred while the user was offline.
 * It checks local storage every 5 minutes to see if there are any events to send
 * and if the user is online, it will send them.
 */
export function useSendOfflineMixpanelEvents(
  internetConnectionStatus: InternetConnectionStatus
) {
  useInterval(() => {
    if (internetConnectionStatus !== InternetConnectionStatus.ONLINE) {
      return;
    }

    const offlineEvents =
      getLocalStorageItem({
        key: MixpanelOfflineEventsKey,
        schema: mixpanelOfflineEventsSchema,
      }) ?? [];

    if (offlineEvents.length === 0) {
      return;
    }

    setLocalStorageItem({
      key: MixpanelOfflineEventsKey,
      value: [],
    });

    offlineEvents.forEach(event => {
      const { properties, time, type } = event;

      trackEvent({
        ...properties,
        type,
        time: time.getTime(),
      } as TrackingEventArgs);
    });
  }, SEND_OFFLINE_EVENTS_INTERVAL);
}
