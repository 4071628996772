import { z } from "zod";

export const MixpanelOfflineEventsKey = "mixpanel-offline-events";

export const mixpanelOfflineEventsSchema = z
  .object({
    properties: z.record(z.any()),
    time: z.coerce.date(),
    type: z.string(),
  })
  .passthrough()
  .array();

export type MixpanelOfflineEvent = z.infer<
  typeof mixpanelOfflineEventsSchema
>[number];
