import { createContext, useContext, type PropsWithChildren } from "react";

import { useSendOfflineMixpanelEvents } from "#src/hooks/mixpanel/useSendOfflineMixpanelEvents.tsx";
import {
  type InternetConnectionStatus,
  useInternetConnectionStatus,
} from "#src/hooks/useRootContext/hooks/useInternetConnectionStatus.tsx";

type RootContextValue = {
  internetConnectionStatus: InternetConnectionStatus;
};

const RootContext = createContext<RootContextValue | null>(null);

export function RootProvider({ children }: PropsWithChildren) {
  const internetConnectionStatus = useInternetConnectionStatus();

  useSendOfflineMixpanelEvents(internetConnectionStatus);

  return (
    <RootContext.Provider value={{ internetConnectionStatus }}>
      {children}
    </RootContext.Provider>
  );
}

export function useRootContext() {
  const context = useContext(RootContext);
  if (!context) {
    throw new Error("useRootContext must be used within a RootProvider");
  }
  return context;
}
